import {extendApi} from '@anatine/zod-openapi';
import {DateTime} from 'luxon';
import {z} from 'zod';

const addressSpec = z.object({
  addressLine1: z.string(),
  addressLine2: z.string().optional(),
  contactName: z.string(),
  countryCode: z.string(),
  locality: z.string(),
  phoneNumber: z.string(),
  postcode: z.string(),
  region: z.string(),
});

const warehouseSpec = z.object({
  address: z.object({
    name: z.string(),
    addressLine1: z.string(),
    city: z.string(),
    stateProvince: z.string(),
    zip: z.string(),
    countryCode: z.string(),
    country: z.string(),
  }),
  locationType: z.enum(['home', 'counter', 'locker', 'warehouse', 'station']),
  locationCode: z.string(),
  locationOwnerCode: z.string(),
});

export const partialTenantConfigSpec = z.object({
  address: addressSpec,
  defaultLanguage: z.enum(['en', 'fr']),
  faxNumbers: z.union([z.object({main: z.string()}).passthrough(), z.record(z.string())]),
  name: z.string(),
  // TODO switch to stripe or generic payment processor
  paysafe: z.object({
    accounts: z.object({
      cards: z.string(),
      paypalApm: z.string(),
    }),
    apiKeyId: z.string(),
    publicKey: z.string(),
  }),
  shipping: z.object({
    expedibox: z.object({
      organizationId: z.string(),
    }),
    intelcom: z.object({
      shipmentConsignee: warehouseSpec,
    }),
    shipEngine: z.object({
      warehouseId: z.string(),
    }),
    vaistat: z.object({
      baseUrl: z.string(),
      pharmacyId: z.string(),
    }),
  }),
  type: z.literal('partial'),
  zendeskOrganisationId: z.string(),
});

export const completeTenantConfigSpec = z.object({
  address: addressSpec,
  apiBaseUrl: z.string(),
  apiWsBaseUrl: z.string(),
  autoRenewCycleStartDate: extendApi(
    z.string().superRefine((s, ctx) => {
      if (s === null || s === undefined) return;
      const date = DateTime.fromISO(s);
      if (date.invalidExplanation) {
        ctx.addIssue({
          code: 'custom',
          message: date.invalidExplanation,
        });
      }
    })
  ),
  calendly: z.object({
    consultationBookingLinks: z.object({
      genericFollowUp: z.object({
        en: z.string(),
        fr: z.string(),
      }),
      patientCare: z.object({
        en: z.string(),
        fr: z.string(),
      }),
      patientCareIntro: z.object({
        en: z.string(),
        fr: z.string(),
      }),
      pfupObesity: z.object({
        en: z.string(),
        fr: z.string(),
      }),
      transferRequest: z.object({
        en: z.string(),
        fr: z.string(),
      }),
      prescriptionUpload: z.object({
        en: z.string(),
        fr: z.string(),
      }),
    }),
  }),
  defaultLanguage: z.enum(['en', 'fr']),
  empego: z.object({
    username: z.string(),
  }),
  faxNumbers: z.union([z.object({main: z.string()}).passthrough(), z.record(z.string())]),
  name: z.string(),
  paysafe: z.object({
    accounts: z.object({
      cards: z.string(),
      paypalApm: z.string(),
    }),
    apiKeyId: z.string(),
    publicKey: z.string(),
  }),
  shipping: z.object({
    expedibox: z.object({
      organizationId: z.string(),
    }),
    intelcom: z.object({
      shipmentConsignee: warehouseSpec,
    }),
    shipEngine: z.object({
      warehouseId: z.string(),
    }),
    vaistat: z.object({
      baseUrl: z.string(),
      pharmacyId: z.string(),
    }),
  }),
  storageBucket: z.string(),
  type: z.literal('complete'),
  zendeskOrganisationId: z.string(),
});

export const tenantSpec = z.discriminatedUnion('type', [
  completeTenantConfigSpec,
  partialTenantConfigSpec,
]);

export const globalConfigSpecV1 = z.object({
  defaultTenant: z.string(),
  featureFlags: z.object({
    aiRamq: z.boolean(),
    automaticRenewal: z.boolean(),
    expediboxPickUp: z.boolean(),
    foo: z.string().optional(), // Removed 2023-11
    mondouPickUp: z.boolean(),
    petDependents: z.boolean(),
    rensPetsPickUp: z.boolean(),
  }),
  ramqApi: z.object({
    baseUrl: z.string(),
  }),
  tenants: z.record(tenantSpec),
});

export type TenantConfigType = 'complete' | 'partial';
export type GlobalConfigV1 = z.infer<typeof globalConfigSpecV1>;
export type CompleteTenantConfig = z.infer<typeof completeTenantConfigSpec>;
export type PartialTenantConfig = z.infer<typeof partialTenantConfigSpec>;
export type TenantConfig<T extends TenantConfigType> = T extends 'complete'
  ? CompleteTenantConfig
  : PartialTenantConfig;

export const isCompleteTenantConfig = (
  config: TenantConfig<TenantConfigType>
): config is CompleteTenantConfig => config.type === 'complete';

export const isPartialTenantConfig = (
  config: TenantConfig<TenantConfigType>
): config is CompleteTenantConfig => config.type === 'complete';
