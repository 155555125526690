import {impossible} from 'utils/assert';
import {version} from '../../package.json';

const parseEnvBoolean = (value: string | null | undefined, fallback: boolean = false) => {
  if (value === null || value === undefined) return fallback;

  try {
    return Boolean(JSON.parse(value.split(/\s+/)[0]));
  } catch (_error) {
    return fallback;
  }
};

const parseEnvInt = (value: string | null | undefined, fallback: number = 0) => {
  if (value === null || value === undefined) return fallback;

  try {
    return parseInt(JSON.parse(value.split(/\s+/)[0]), 10);
  } catch (_error) {
    return fallback;
  }
};

const config = {
  application: {
    canonicalUrl: process.env.REACT_APP_CANONICAL_URL ?? impossible(),
    version,
    globalConfigFromFirestore: parseEnvBoolean(process.env.REACT_APP_GLOBAL_CONFIG_FROM_FIRESTORE),
  },

  featureFlags: {
    autonomousOrders: parseEnvBoolean(process.env.REACT_APP_AUTONOMOUS_ORDERS),
    debugSignInScenarios: parseEnvBoolean(process.env.REACT_APP_DEBUG_SIGN_IN_SCENARIOS),
    debugTenants: parseEnvBoolean(process.env.REACT_APP_DEBUG_TENANTS),
    medicationDetails: parseEnvBoolean(process.env.REACT_APP_MEDICATION_DETAILS),
    newFollowUpsFlow: parseEnvBoolean(process.env.REACT_APP_NEW_FOLLOW_UPS_FLOW),
    newPatientCareDashboard: parseEnvBoolean(process.env.REACT_APP_NEW_PATIENT_CARE_DASHBOARD),
    showVersionNumber: parseEnvBoolean(process.env.REACT_APP_SHOW_VERSION_NUMBER),
    signInWithApple: parseEnvBoolean(process.env.REACT_APP_SIGN_IN_WITH_APPLE),
    xstateInspection: parseEnvBoolean(process.env.REACT_APP_XSTATE_INSPECTION),
  },

  apollo: {
    apiGraphqlPath: process.env.REACT_APP_API_GRAPHQL_PATH ?? impossible(),
  },

  codepush: {
    android: {
      deploymentKey: process.env.REACT_APP_ANDROID_CODEPUSH_DEPLOYMENT_KEY,
    },
    ios: {
      deploymentKey: process.env.REACT_APP_IOS_CODEPUSH_DEPLOYMENT_KEY,
    },
  },

  empego: {
    franchiseName: process.env.REACT_APP_EMPEGO_FRANCHISE_NAME ?? impossible(),
  },

  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    // storageBucket: We use one for each tenant, specified in the global config
  },

  recaptchaEnterprise: {
    siteKey: process.env.REACT_APP_RECAPTCHA_ENTERPRISE_SITE_KEY ?? impossible(),
  },

  firebaseFunctions: {
    url: process.env.REACT_APP_FIREBASE_FUNCTIONS_URL ?? impossible(),
  },

  maps: {
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? impossible(),
  },

  googleTagManager: {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
    auth: process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
    preview: process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW,
    src: process.env.REACT_APP_GOOGLE_TAG_MANAGER_SRC,
  },

  hashids: {
    salt: process.env.REACT_APP_HASHIDS_SALT,
    minLength: parseEnvInt(process.env.REACT_APP_HASHIDS_MIN_LENGTH),
  },

  idleTimer: {
    delayInMinutes: parseEnvInt(process.env.REACT_APP_IDLE_TIMER_DELAY_IN_MINUTES),
  },

  paysafe: {
    apiKey: process.env.REACT_APP_PAYSAFE_API_KEY,
    environment: process.env.REACT_APP_PAYSAFE_ENVIRONMENT,
  },

  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT_NAME,
  },

  vigilance: {
    baseUrl: process.env.REACT_APP_VIGILANCE_RX_BASE_URL ?? impossible(),
  },

  servicesInvoices: {
    activationDate: process.env.REACT_APP_SERVICES_INVOICES_ACTIVATION_DATE ?? impossible(),
  },

  orphanedServices: {
    gracePeriod: {
      hours: process.env.REACT_APP_ORPHANED_SERVICES_GRACE_PERIOD ?? impossible(),
    },
  },

  zendesk: {
    chatWidgetKey: process.env.REACT_APP_ZENDESK_CHAT_WIDGET_KEY,
  },

  adobeSign: {
    patientCarePrescriptionAgreementUrl:
      process.env.REACT_APP_ADOBE_SIGN_PATIENT_CARE_PRESCRIPTION_AGREEMENT_URL,
    consultationPrescriptionAgreementUrl:
      process.env.REACT_APP_ADOBE_SIGN_CONSULTATION_PRESCRIPTION_AGREEMENT_URL,
    labAgreementUrl: process.env.REACT_APP_ADOBE_SIGN_LAB_AGREEMENT_URL,
  },
};

export default Object.freeze(config);
